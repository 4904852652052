import React from 'react'
import type { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby'

import { loadMatomoAndTrack, trackPageView } from './src/tracking/matomo'
import { loadMyFonts } from './src/tracking/myfonts'
import {
  bootstrapCookieFirst,
  onCookieFirstServiceUpdate,
} from './src/tracking/cookieFirst'
import { loadLinkedIn } from './src/tracking/linkedIn'
import { loadFacebook } from './src/tracking/facebook'
import {
  loadGoogleAnalytics,
  trackGoogleAnalyticsPageView,
} from './src/tracking/analytics'
import { HeaderContextProvider } from './src/contexts/HeaderContext'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (ref) => {
  trackPageView(ref)
  trackGoogleAnalyticsPageView(ref.location)
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = (
  args,
  options
) => {
  if (process.env.NODE_ENV !== 'production' || process.env.GATSBY_FORCE_FONTS) {
    loadMyFonts()
    return
  }

  bootstrapCookieFirst()

  onCookieFirstServiceUpdate((accepted) => {
    if (accepted.includes('matomo')) {
      loadMatomoAndTrack(window.location)
    }
  })

  onCookieFirstServiceUpdate((accepted) => {
    if (accepted.includes('myfonts')) {
      loadMyFonts()
    }
  })

  onCookieFirstServiceUpdate((accepted) => {
    if (accepted.includes('facebook')) {
      loadFacebook()
    }
  })

  onCookieFirstServiceUpdate((accepted) => {
    if (accepted.includes('linkedin_ads')) {
      loadLinkedIn()
    }
  })
}
